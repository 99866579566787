import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import AeroDesk from "../../images/landing/aero.jpg"
import Car from "../../images/landing/car1800.jpg"
import CarTab from "../../images/landing/car1800.jpg"
import CarDesk from "../../images/landing/car1800.jpg"
import propertyTab02 from "../../images/landing/1802.jpg"
import DownTownDesk from "../../images/landing/1803.png"
import Location01 from "../../images/landing/place01.png"
import Location02 from "../../images/landing/place02.png"
import Location03 from "../../images/landing/place03.png"
import Location04 from "../../images/landing/place04.png"
import Location05 from "../../images/landing/place05.jpg"
import { useWindowSize } from "../../hooks/window-size"
import parse from "html-react-parser"

import AreaguideCard from "../AreaguideCard/AreaguideCard"

import "./SelectNews.scss"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import SelectNewsCard from "./SelectNewsCard"
import { graphql, useStaticQuery } from "gatsby";


const SelectNews = ({ module, currentNews }) => {

  const [windowWidth] = useWindowSize()

  const newsData = useStaticQuery(graphql`
  query GetOtherNews {
    glstrapi {
      newsInsights {
        URL
            Tile_Image {
              url
            }
            Select_Category {
              Name
              URL
              id
            }
            imagetransforms
            id
            Title
      }
    }
  }
  `)

  const news = newsData.glstrapi.newsInsights

  const filteredNews = news.filter((news) => news.id !== currentNews.id); 


  return (
    <>
      <section className="select-news">
        <div className="select-news-wrap">
          <Container>
            <div className="intro">
              <div className="intro-content">
                <h2 className="heading">{module.Module_Heading}</h2>
                {module.Module_Content && parse(module.Module_Content)}
              </div>
            </div>

            <div className="news-cards">
              {filteredNews.slice(0,4).map(news => (
                <SelectNewsCard key={news.id} newsCard={news} />
              ))}
            </div>
          </Container>
        </div>
      </section>
    </>
  )
}
export default SelectNews
