import React, { useState } from "react"
import { graphql } from "gatsby"

import Header from "../components/Header/Header"
import SEO from "../components/Seo/seo"
import PropertyFeatures from "../components/PropertyFeatures/PropertyFeatures"
import Help from "../components/Help/Help"
import Footer from "../components/Footer/Footer"
import NewsDetailSection from "../components/NewsDetailSection/NewsDetailSection"
import SelectNews from "../components/SelectNews/SelectNews"

const NewsDetailPage = props => {
  const news = props.data.glstrapi.newsInsight
  const otherNews = props.data.glstrapi.globalConfig.Other_News
  let metadesc = "Read about "+news.Title+" here and subscribe to our newsletter to stay up-to-date about everything going on at McCone properties."
  return (
    <div className="news-detail-page">
      <SEO
        title={news.Meta_Title ? news.Meta_Title : news.Title}
        description={news.Meta_Description ? news.Meta_Description : metadesc}
        image ={news.Tile_Image && news.Tile_Image.url}
      />
      <Header alt />
      <NewsDetailSection news={news} />
      <div className="container d-none d-lg-block horizontal-divider"></div>
      <SelectNews module={otherNews} currentNews={news} />
      <Help module="Lets_Work_Together_Module" />
      <Footer popularsearch= "Sale_Search"/>
    </div>
  )
}

export default NewsDetailPage

export const pageQuery = graphql`
  query GetNewsInsights($articleId: ID!) {
    glstrapi {
      newsInsight(id: $articleId, publicationState: LIVE) {
        URL
        id
        Title
        Video_URL
        Above_Intro_Content
        Above_Intro_Image {
          url
        }
        Below_Intro_Content
        Below_Intro_Image {
          url
        }
        
        Description
        Meta_Description
        Meta_Title
        News_Date
        Reading_Time
        Select_Category {
          Name
          URL
          id
        }
        Select_Staff {
          Email
          Designation
          URL
          Expertise
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          Name
          Phone
          languages
          imagetransforms
        }
        imagetransforms
        Tile_Image {
          url
        }
      }

      globalConfig {
        Other_News {
          Module_Heading
          Select_News {
            URL
            Tile_Image {
              url
            }
            Select_Category {
              Name
              URL
              id
            }
            imagetransforms
            id
            Title
          }
          id
        }
      }
    }
  }
`
