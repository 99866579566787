import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AnimationOnScroll } from "react-animation-on-scroll"
import ContactCard from "../../components/ContactSidebarCard/ContactSidebar"
import ContactCardWO from "../ContactSidebar/ContactSidebarNoStaff"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"
import "./NewsDetailSection.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Link } from "gatsby"
import {Helmet} from "react-helmet"
import {useLocation} from "@reach/router"
import { useWindowSize } from "../../hooks/window-size"
import Share from "../share/share"
import moment from "moment"
import SiteBreadcrumbs from "../BreadCrumbs/site-breadcrumbs"
import Sticky from 'react-stickynode';
import parse from "html-react-parser"
import PlayVideo from "../PlayVideo/PlayVideo";
import darkBrandLogo02 from "../../images/logo-dark-tab.svg";

const NewsDetailSection = ({ news }) => {
  const location = useLocation()
  const staff = news.Select_Staff
  const [windowWidth] = useWindowSize()
  const [isPlay, setIsPlay] = useState(false);

  const formattedDate = news.News_Date
    ? moment(news.News_Date).format("Do MMMM YYYY")
    : ""

  return (
    <div className="news-detail-section">
      <Helmet>
      <script type="application/ld+json">{`{
          "@context": "http://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${location?.href}"
          },
          "headline": "${news?.Title}",
          "image": [
            "${news?.Tile_Image?.url}"
          ],
          "datePublished": "${news?.News_Date}",
          "dateModified": "${news?.News_Date}",
          "author": {
          "@type": "Person",
          "name": "Mccone Properties"
          },
          "publisher": {
          "@type": "Organization",
          "name": "McCone Proeprties - Real Estate Agents in Dubai",
          "logo": {
          "@type": "ImageObject",
          "url": "${darkBrandLogo02}"
          }
          },
          "description": "${news?.Meta_Description}"
          
        }`} 

        </script>
      </Helmet>
      <Container>
        <div className="news-detail-content col-xl-8 col-lg-8 col-md-12">
          <SiteBreadcrumbs
            style="grey-breadcrumbs"
            baselabel="News & Video"
            baseurl="/about/latest-property-news-and-video/"
            menulabel={parse(news.Title)}
          />
          <h1 className="news-title">{parse(news.Title)}</h1>
          <div className="news-sm">
            <span className="date">{formattedDate}</span>
            <span className="vertical-divider"></span>
            <span className="category">{news.Select_Category.Name}</span>
            <span className="vertical-divider"></span>
            <span className="like-share">
              <Share text="Share" />
            </span>
          </div>
        </div>
        <Row>
          <Col md={12} lg={8} xl={8}>
            <div className="news-detail-content">
              <div className="content">
                <div className="above-image">
                  {news.Above_Intro_Image && <img src={news.Above_Intro_Image.url} alt="above-imge" />}
                  {news.Video_URL && <div className="play-btn play-button" onClick={() => {setIsPlay(true);}}>
                  <i class="video-btn"></i>
                  </div>}
                  {isPlay && news.Video_URL &&
                    <PlayVideo
                      isOpen={isPlay}
                      videoId="V8ksSGxo6no"
                      isCloseFunction={setIsPlay} 
                      videourl={news.Video_URL}
                    />
                  }
                </div>
                <p className="above-content">
                  {news.Above_Intro_Content && parse(news.Above_Intro_Content)}
                </p>
                <div className="below-image">
                  {news.Below_Intro_Image && <img src={news.Below_Intro_Image.url} alt="below-imge" />}
                </div>
                <p className="below-content">
                  {news.Below_Intro_Content && parse(news.Below_Intro_Content)}
                </p>
                <div className="stop-sticky"></div>
              </div>
              {staff &&
                (windowWidth > 992 ? null : (
                  <div className="staff-info">
                    <div className="staff-wrap">
                      <Link to={`/meet-the-team/${staff.URL}`}>
                        <div className="img-wrap zoom">
                          {/* <img src={contactImg} alt="contact" /> */}
                          <GetGatsbyImage
                            image={staff.Image}
                            fallbackalt={staff.Name + " - " + staff.Designation}
                          />
                        </div>
                        <div className="staff-right">
                          <div>
                            <p className="staff-name">{staff.Name}</p>
                            <p className="staff-position">
                              {staff.Designation}
                            </p>
                          </div>

                          <span className="staff-contact">
                            <span className="title">Languages: </span>English,
                            Urdu
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col lg={4} xl={4}>
                <div className="contactCard">
                <Sticky top=".header" bottomBoundary=".stop-sticky">
                  <ContactCardWO isBlogpage={true}/>
                  </Sticky>
                </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsDetailSection
