import React from "react";
import { Link } from "gatsby";
import GetGGFXImage from "../common/site/get-ggfx-image";

import "./SelectNewsCard.scss"

const SelectNewsCard = (props) => {
    const NewsItem = props.newsCard;
    
    let processedImages = JSON.stringify({});
  if (NewsItem?.imagetransforms?.Tile_Image_Transforms) {
   processedImages = NewsItem.imagetransforms.Tile_Image_Transforms;
  }
  return (
    <React.Fragment>
      <Link
        to={"/about/latest-property-news-and-video/" + NewsItem.URL + "/"}
        className="d-inline-block zoom"
      >
        <div className="news-insight-card zoom">
          <div className="news-card-wrapper">
            <GetGGFXImage
              imagename={"news-insights.Tile_Image.tileimg"}
              imagesources={NewsItem.Tile_Image}
              fallbackalt={NewsItem.Title}
              imagetransformresult={processedImages}
              id={NewsItem.id}
            />
          </div>
          <div className="news-content">
            <p className="category">
              {NewsItem.Select_Category && NewsItem.Select_Category.Name}
            </p>
            <p className="title">{NewsItem.Title}</p>
          </div>
        </div>
      </Link>
    </React.Fragment>
  )
}

export default SelectNewsCard