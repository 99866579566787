/**
 * External dependencies
 */
import React, { useState } from "react";
import classNames from "classnames";
import { useLocation } from "@reach/router"
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from "react-share";
import {
	EmailIcon,
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon
} from "react-share";
import {Link}  from "gatsby"

const Share = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleShareClick = () => setIsOpen(!isOpen);

  // Social share
	const location = useLocation();

	const shareurl = typeof window !== 'undefined' ? window.location.href : ''
	
	const [Shareicons,setShareicons] = React.useState(false);
	
	const openShareicons = () => {
	setShareicons(true);
	if(Shareicons === true) {
		setShareicons(false);
	}
	}

	const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);
	
	const openBookViewformModal = () => {
		setBookViewingformOpen(true);
	}

	const trackerShare = (event) => {    
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'Share - social icons',
		'formType': event + ' - ' +shareurl,
		'formId': 'Share',
		'formName': 'Share',
		'formLabel': 'Share'
	});    
	}
	// Social share

  return (
    // <div className={classNames(`share`, { "is-open": isOpen })}>
    <div className={"share social-share-wrapper"}>
      {props.title?
      <a href="javascript:;" onClick={openShareicons}>{props.title}</a>
      :
      <a className="share" onClick={openShareicons}>
        <i className={props.iconclass?props.iconclass:"icon-share-light"}></i>
        {props.text && <span>{props.text}</span>}
      </a>
      }
      {Shareicons &&
        <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
          <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
            <FacebookIcon size={32} round={false} borderRadius={`10`} />
          </FacebookShareButton>
          <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
            <TwitterIcon size={32} round={false} borderRadius={`10`} />
          </TwitterShareButton>
          <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
          </LinkedinShareButton>
          <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
          </WhatsappShareButton>
          {/* <EmailShareButton url={`/send-to-friend?pid=${props.id}`}   onClick={()=> { trackerShare('SendToFriend')}} className="my-share-button email-share">
            <EmailIcon size={32} round={false} borderRadius={`10`} />
          </EmailShareButton> */}
          {props.id &&
            <Link to={`/send-to-friend?pid=${props.id}`} className="react-share__ShareButton my-share-button email-share">          
              <button className="react-share__ShareButton my-share-button email-share">
                <EmailIcon size={32} round={false} borderRadius={`10`} />
              </button>
            </Link>
          }
        </div>
      }
      {/* <ul>
        <li>
          <a href="/">
            <i className="icon-s-facebook"></i>
          </a>
        </li>
        <li>
          <a href="/">
            <i className="icon-s-twitter"></i>
          </a>
        </li>
        <li>
          <a href="/">
            <i className="icon-s-linkedin"></i>
          </a>
        </li>
        <li>
          <a href="/">
            <i className="icon-s-whatsapp"></i>
          </a>
        </li>
        <li>
          <a href="/">
            <i className="icon-s-mail"></i>
          </a>
        </li>
      </ul> */}
    </div>
  )
}

export default Share
